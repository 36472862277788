import React from 'react';
import { NavDropdown } from 'react-bootstrap';

class NavigationDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  gameDropdownItems = (gameList) => {
    var items = []
    items.push(
      <NavDropdown.Item href={this.props.href}>{`All ${this.props.title}`}</NavDropdown.Item>
    )
    gameList.forEach(game => {
      items.push(
        <NavDropdown.Item href={game.route}>{game.title}</NavDropdown.Item>
      )
    });
    return items;
  }

  render() {
    return (
      // show={this.state.isOpen}
      <NavDropdown onMouseEnter={ this.handleOpen } onMouseLeave={ this.handleClose } title={this.props.title}>
        {this.gameDropdownItems(this.props.gameList)}
      </NavDropdown>
    )
  }
}
 
export default NavigationDropdown;