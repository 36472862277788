import React from 'react'
import { TWITTER } from '../constants/links'

const email = "ajknollmeyer@gmail.com"
const phone = "(857) 283-3444"

const ContactInfo = () => {
  return (
    <small>
      <a href={"mailto:" + email}>{email}</a> &#183; <a href={"tel:" + phone}>{phone}</a> &#183; <a href={TWITTER}>@belleknollmeyer</a>
    </small>
  )
}

export default ContactInfo