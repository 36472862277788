import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import CustomButton from './CustomButton';
import IconList from './IconList';
import Showcase from './Showcase'
import { RESUME } from '../constants/links'
import * as ROUTES from '../constants/routes'
import * as GAMES from '../constants/games'
import * as PROJECTS from '../constants/projects'

const FEATURED = [GAMES.PLUTO, PROJECTS.ENGINE, GAMES.CTRL, PROJECTS.GRAPHICS]

const HomePage = () => (
	<div className="w-100 h-100">
		<div className="splash">
			<Container fluid="md">
				<Row className="justify-content-center">
					<Col md={4} sm={6} xs={8} className="portrait">
						<Image src="/img/portrait.png" alt="Portrait of Belle Knollmeyer" />
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md={6} sm={8} xs={12} className="portrait-box">
						<Row><Col><h1 className="portrait-name">Belle Knollmeyer</h1></Col></Row>
						<Row><Col><h6 className="portrait-description">✨ (she/her) ✨</h6></Col></Row>
						<Row><Col><p className="portrait-description"><b>Associate Tools Automation Programmer,<br/>Rockstar Games</b></p></Col></Row>
					</Col>
				</Row>
			</Container>
		</div>
		<Container fluid="md">
			<Row className="justify-content-center">
				<a href={RESUME}><CustomButton text="View Resume" /></a>
			</Row>
			<Row className="justify-content-center mt-3 mb-2">
				<IconList placement="bottom" hideResume />
			</Row>
			<hr className="my-5" />
			<Showcase title="Featured Work" games={FEATURED} />
			<Row className="justify-content-center">
				<a className="m-2" href={ROUTES.GAMES}><CustomButton text="More Games" arrow /></a>
				<a className="m-2" href={ROUTES.PROJECTS}><CustomButton text="Other Projects" arrow /></a>
			</Row>
		</Container>
	</div>
)

export default HomePage;