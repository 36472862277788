import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const IconLink = (props) => (
  <OverlayTrigger
    placement={props.placement}
    delay={{ show: 250, hide: 250 }}
    overlay={<Tooltip>{props.tooltip}</Tooltip>}>
    <a className="icon-link" href={props.link}>
      <FontAwesomeIcon icon={props.icon} />
    </a>
  </OverlayTrigger>
)

export default IconLink;