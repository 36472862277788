import React from 'react'
import { Col, Row } from 'react-bootstrap'
import GamePreview from './GamePreview'

function Title(props) {
  if (props.titleLink) {
    return (
      <a href={props.titleLink}><h2 className="big-text mt-2">{props.title}</h2></a>
    )
  } else {
    return (
      <h1 className="big-text mt-2">{props.title}</h1>
    )
  }
}

function Previews(props) {
  var previews = []

  props.games.forEach(game => {
    previews.push(GamePreview(game));
  });

  return previews
}

const Showcase = (props) => (
  <Row className="justify-content-center">
    <Col md={8} sm={10} xs={11}>
      <Row className="justify-content-center">
        {Title(props)}
      </Row>
      {Previews(props)}
    </Col>
  </Row>
)

export default Showcase