import React from 'react'
import { Carousel, Image } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { SRLWrapper } from 'simple-react-lightbox';
import CarouselContent from './CarouselContent';
import CustomButton from './CustomButton';

class GameData {
  constructor(route, title, timeframe, role, trailer, imageDatas, shortDescription, links, about, contributions, lessons) {
    this.route = route
    this.title = title
    this.timeframe = timeframe
    this.role = role
    this.trailer = trailer
    this.imageDatas = imageDatas
    this.shortDescription = shortDescription
    this.links = links
    this.about = about
    this.contributions = contributions
    this.lessons = lessons
  }

  PreviewVisual() {
    if (this.trailer) {
      return this.Trailer()
    } else {
      return this.Carousel()
    }
  }

  Trailer() {
    return (
      <div className="aspect-ratio-16x9">
        <YouTube containerClassName="aspect-ratio-inner mb-2" className="youtube-video" videoId={this.trailer} />
      </div>
    )
  }

  Carousel() {
    const carouselItems = []

    const lightboxOptions = {
      buttons: {
        showAutoplayButton: false,
        showCloseButton: true,
        showDownloadButton: false,
        showFullscreenButton: false,
        showNextButton: false,
        showPrevButton: false,
        showThumbnailsButton: false
      },
      thumbnails: {
        showThumbnails: false
      }
    }

    this.imageDatas.forEach(imageData => {
      carouselItems.push(
        <Carousel.Item>
          <CarouselContent>
            <SRLWrapper options={lightboxOptions}>
              <a href={imageData[0]}>
                <Image
                  src={imageData[0]}
                  alt={imageData[1]}
                />
              </a>
            </SRLWrapper>
          </CarouselContent>
        </Carousel.Item>
      )
    })

    return (
      <Carousel className="w-100" interval={null}
        nextIcon={<div className="carousel-control-bg"><span aria-hidden="true" className="carousel-control-next-icon m-auto" /></div>}
        prevIcon={<div className="carousel-control-bg"><span aria-hidden="true" className="carousel-control-prev-icon m-auto" /></div>}>
        {carouselItems}
      </Carousel>
    )
  }

  LinkButtons() {
    const linkButtons = []

    if (this.links === undefined || this.links.length === 0) {
      return null
    }
    else {
      this.links.forEach(link => {
        linkButtons.push(
          <a href={link[0]} className="m-2">
            <CustomButton text={link[1]} arrow />
          </a>
        )
      })
    }

    return linkButtons
  }
}

export default GameData