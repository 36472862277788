import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotFoundPage = () => (
  <Container fluid="md" className="my-auto">
    <Row className="justify-content-center">
      <Col md={8} sm={10} xs={12} className="not-found">
        <FontAwesomeIcon icon="question" className="w-100 fa-9x my-4" />
        <h1 className="bold">Huh?</h1>
        <p>The page you're looking for doesn't seem to exist. Check the URL at the top.</p>
        <p><i>If you got here from a link on this site, <strong>please get in touch and let me know!</strong></i></p>
      </Col>
    </Row>
  </Container>
)

export default NotFoundPage