import React from 'react'
import { Container } from 'react-bootstrap'
import Showcase from './Showcase'
import GAMELIST from '../constants/games'

const GamesPage = () => (
  <Container fluid="md">
    <br />
    <Showcase title="Games" games={GAMELIST} />
  </Container>
)

export default GamesPage