import React from 'react'
import { Container } from 'react-bootstrap'
import Showcase from './Showcase'
import PROJECTLIST from '../constants/projects'

const ProjectsPage = () => (
  <Container fluid="md">
    <br />
        <Showcase title="Projects" games={PROJECTLIST} />
  </Container>
)

export default ProjectsPage