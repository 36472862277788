import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { RESUME } from '../constants/links'
import CustomButton from './CustomButton';
import ContactInfo from './ContactInfo';

const AboutPage = () => (
	<div className="w-100 h-100">
		<Container fluid="md">
			<Row className="justify-content-center my-2">
				<Col>
					<Image src="/img/about/gallery.png" alt="Belle posing at a gallery" rounded />
				</Col>
				<Col>
					<Image src="/img/about/parade.png" alt="Belle and her partner at the Boston Dyke March" rounded />
				</Col>
			</Row>
			<Row className="justify-content-center">
				<Col md={8} sm={10} xs={12} className="portrait-box">
					<h1 className="portrait-name">Hi! I'm Belle ✨</h1>
				</Col>
			</Row>
			<Row className="justify-content-center blue-link">
				<Col md={8} sm={10} xs={12}>
					<p>
						I'm a programmer and game designer in Boston, MA.
						I graduated from <b>Northeastern University</b> in May 2021 with a bachelor's degree in Computer Science and Game Development.
						Right now, I'm an <b>Associate Tools Automation Programmer at Rockstar Games</b>.
						<br /><br />
						Yes I'm a trans woman, and yes I'm damn proud of it!
						I try to wear my queerness on my sleeve not just because I like being myself, but also to encourage those around me to feel comfortable expressing their truest selves every day.
						<br /><br />
						I'm most interested in systems, engine, and graphics programming, although I enjoy any programming work that presents engaging coding problems to solve.
						Working with performance and optimization in mind just makes for a more interesting challenge.
						<br /><br />
						In addition to just coding, I have experience configuring and managing automated building and deployment of projects for PC, console, web, and mobile.
						I also have experience with game and UX design tasks including paper prototyping, managing playtests, and maintaining design documents.
						<br /><br />
						In my personal time I enjoy local art, thrift shopping, fighting games, music festivals, cooking, and cats.
					</p>
				</Col>
			</Row>
			<Row className="justify-content-center">
				<Col md={8} sm={10} xs={12} className="portrait-box">
					<h5 className="portrait-name">Get In Touch</h5>
				</Col>
			</Row>
			<Row className="justify-content-center mb-3">
				<Col md={8} sm={10} xs={12} className="portrait-box">
					<h5 className="text-center blue-link"><ContactInfo /></h5>
				</Col>
			</Row>
			<Row className="justify-content-center">
				<a href={RESUME} className="mx-2">
					<CustomButton text="View Resume" arrow />
				</a>
			</Row>
		</Container>
	</div>
)

export default AboutPage;