import * as ROUTES from './routes'
import GameData from '../components/GameData'

// Pluto's Ascent
const plutoIOS = "https://apps.apple.com/us/app/plutos-ascent/id1519453027"
const plutoAndroid = "https://play.google.com/store/apps/details?id=com.massdigi.porkdumpling&hl=en_US&gl=US"
const massDIGI = "https://www.massdigi.org/programs-services/summer-innovation-program/"
const plutoScopa = "https://en.wikipedia.org/wiki/Scopa"
const plutoCodeStyle = "https://docs.google.com/document/d/12MkJ5jqOEBsf9F2DtkLa7vX5QgI7nnaITemsuOFN8wk/edit?usp=sharing"
const plutoVersionControl = "https://docs.google.com/document/d/1F7TC6bvHzL_WYE7eg_53gH4DfOcoTNpAC8qNPiDMzLQ/edit"
const plutoSkillCreationGuide = "https://docs.google.com/document/d/1sV_140DWm5BS2chJhrmsGLNvk_PaWQjSCiRtrDb3QCk/edit?usp=sharing"
const plutoMiro = "https://miro.com/"
export const PLUTO = new GameData(
  ROUTES.PLUTO,
  "Pluto's Ascent",
  "May-Aug 2020",
  "Lead Programmer, Build Manager",
  "QwZsQNN0mHc",
  [
    ["/img/pluto/pluto1.png", "Pluto's Ascent title screen"],
    ["/img/pluto/pluto2.png", "Pluto's Ascent battle screen"],
    ["/img/pluto/pluto3.png", "Pluto's Ascent map screen"],
    ["/img/pluto/pluto4.png", "Miro board used for production"]
  ],
  (<p>
    Battle cosmic deities to restore Pluto's rightful status within the solar system.
    Made as part of <a href={massDIGI}>MassDiGI's 2020 Summer Innovation Program (SIP)</a>.
  </p>),
  [
    [plutoIOS, "App Store"],
    [plutoAndroid, "Play Store"]
  ],
  (<p>
    Pluto's Ascent is a free-to-play mobile card-based RPG where you battle cosmic deities to restore Pluto's rightful status within the solar system.
    The core gameplay is based on the Italian card game <a href={plutoScopa}>Scopa</a>, with powerful skill cards layered on top for additional strategy and expression.
    <br /><br />
    This game was developed remotely over 3 months as part of a 6-person team for <a href={massDIGI}>MassDiGI's 2020 Summer Innovation Program (SIP)</a>.
    Since my participation in SIP, a live-ops team from MassDiGI has continued working on the game.
    <br /><br />
    <strong>Code available upon request.</strong>
  </p>),
  (<ul>
    <li><p>Designed and developed core systems such as skill cards, turn logic, map screen, and more</p></li>
    <li><p>Integrated data collection and analytics using DevToDev SDK</p></li>
    <li><p>Prioritized programming tasks for team in conjunction with producer</p></li>
    <li><p>Managed building and deploying to App and Play Stores via Unity Cloud Build and Fastlane</p></li>
    <li><p>Authored documents outlining <a href={plutoCodeStyle}>code style guidelines</a> and <a href={plutoVersionControl}>version control practices</a></p></li>
    <li><p>Wrote <a href={plutoSkillCreationGuide}>documentation and guides</a> for continued development by live-ops team</p></li>
  </ul>),
  (<p>
    This was my first time developing a game entirely remotely.
    While that did make greetings and introductions a bit awkward, by the end of the project everyone on the team felt like a best friend despite never having met.
    <br /><br />
    We were successful thanks to a few strategies which we settled on from the start.
    For example, we were always on a video call.
    Even if most of us were muted and not looking for most of the day, it made it super easy to quickly ask someone a question or get the team's opinion on a problem.
    If any of those questions sparked longer conversations, those involved would split off into their own call so as to not disturb the other team members.
    We also kept strictly-defined working hours, because having a routine is one of the most effective ways to stay productive.
    To aid with collaboration, we used <a href={plutoMiro}>Miro</a> to track our kanban board, art references, design plans, and whatever else we could think of in real-time.
    These strategies helped to simulate an in-person working environment, and I believe that was a huge factor in keeping the team happy and functional.
  </p>)
)

// Out of CTRL
const ctrlGMTK = "https://itch.io/jam/gmtk-2020"
const ctrlRating = "https://itch.io/jam/gmtk-2020/rate/694019"
const ctrlItch = "https://ezra-szanton.itch.io/out-of-ctrl"
const ctrlGitHub = "https://github.com/fshh/GMTK-Game-Jam-2020"
export const CTRL = new GameData(
  ROUTES.CTRL,
  "Out of CTRL",
  "Jul 2020",
  "Programmer, Designer",
  "RDJEwZe17V0",
  [
    ["/img/ctrl/ctrl1.gif", "Out of CTRL title screen"],
    ["/img/ctrl/ctrl2.gif", "Out of CTRL end screen"]
  ],
  (<p>
    You find an old computer that wants to chat with you.
    It seems to know more about you than it should.
  </p>),
  [
    [ctrlItch, "Itch.io Page"],
    [ctrlGitHub, "GitHub Repo"]
  ],
  (<p>
    In Out Of CTRL, have a conversation with a mysterious computer that seems to know too much about you by copy and pasting text from its messages.
    <br /><br />
    This game was made in 48 hours for the <a href={ctrlGMTK}>2020 GMTK Game Jam</a>.
    Out of 5,000+ entries, it was <a href={ctrlRating}>ranked 19th for originality and was in the top 5% overall</a>.
    In 6 months, it has garnered over 6,000 total combined downloads and browser plays.
  </p>),
  (<ul>
    <li><p>Developed systems for interacting with text and displaying messages from the computer, in addition to creating much of the UI</p></li>
    <li><p>Collaborated in designing core game mechanic and overall aesthetic</p></li>
    <li><p>Assisted in continued fixes and updates in response to user feedback and in efforts to add branching storylines</p></li>
  </ul>),
  (<p>
    This was my first time working on a game with a strong focus on narrative elements, which presented a unique and interesting challenge.
    Given the focus on narrative, and the time constraints of the game jam, it was important to recognize that the mechanics of the game needed to take a back-seat to let the narrative shine.
    This pushed our team towards designing a core mechanic that was simple to understand and implement.
    The extra development time left over from having such simple interactivity left a lot of room for me to work on features that would enhance the game's atmosphere and user experience.
    These features included the CRT visual effect, background noises, and the pause menu.
  </p>)
)

// Near-Field Gladiator
const nfgGMTK = "https://itch.io/jam/gmtk-2019"
const nfgRating = "https://itch.io/jam/gmtk-2019/rate/463524"
const nfgItch = "https://bellisimoo.itch.io/near-field-gladiator"
const nfgGitHub = "https://github.com/fshh/GMTK-Game-Jam-2019"
export const NFG = new GameData(
  ROUTES.NFG,
  "Near-Field Gladiator",
  "Aug 2019",
  "Programmer, Designer",
  "uVpsBqTMvGY",
  [
    ["/img/nfg/nfg1.gif", ""],
    ["/img/nfg/nfg2.gif", ""],
    ["/img/nfg/nfg3.gif", ""],
    ["/img/nfg/nfg4.gif", ""],
    ["/img/nfg/nfg5.gif", ""]
  ],
  (<p>
    The spectators of the Neon Arena call for tribute; will you give them scrap metal or give them blood?
  </p>),
  [
    [nfgItch, "Itch.io Page"],
    [nfgGitHub, "GitHub Repo"]
  ],
  (<p>
    Near-Field Gladiator is an action-arcade game where you fight waves of murderous robots by using a magnet to fling a deadly glaive around an arena.
    The game was built in 48 hours for the <a href={nfgGMTK}>2019 GMTK Game Jam</a>.
    Out of over 2,500 entries, it <a href={nfgRating}>ranked within the top 100</a>.
  </p>),
  (<ul>
    <li><p>Programmed core player and weapon movement</p></li>
    <li><p>Implemented animations and special effects such as spotlights, camera shake, particle effects, and time dilation</p></li>
    <li><p>Created in-game and title menu screens</p></li>
  </ul>),
  (<p>
    During this game jam I decided to try investing heavily in adding juice and improving game feel, which turned out very well. 
    I'm proud of the dynamic gameplay we were able to create with just a simple physics-based weapon and a few enemy types.
    <br /><br />
    However, going back and playing the game after it was finished made me realize that other aspects such as readability, immersion, and rewards need their fair share of development time as well.
    I think the game would have benefitted greatly from having a little additional time to add some clarifying UI, narrative hooks to draw players into the gameworld, and more motivations to keep playing besides a simple score number.
  </p>)
)

// Samurai Slasher
const samuraiItch = "https://bellisimoo.itch.io/samurai-slasher"
const samuraiGitHub = "https://github.com/fshh/SamuraiSlasher"
export const SAMURAI = new GameData(
  ROUTES.SAMURAI,
  "Samurai Slasher",
  "Mar-Apr 2019",
  "Programmer, Designer",
  "QFJMqo7wX4w",
  [
    ["/img/samurai/samurai1.png", "Samurai Slasher title screen"],
    ["/img/samurai/samurai2.png", "Players being shown a prompt during a duel"],
    ["/img/samurai/samurai3.png", "A player dash-attacking towards another player"],
    ["/img/samurai/samurai4.png", "A player winning a duel"],
    ["/img/samurai/samurai5.png", "Scoreboard between rounds"]
  ],
  (<p>
    You and 3 other players slice each other up in a fast-paced battle to become The Shogun.
  </p>),
  [
    [samuraiItch, "Itch.io Page"],
    [samuraiGitHub, "GitHub Repo"]
  ],
  (<p>
    Samurai Slasher is a fast-paced minimalistic local multiplayer fighting game about patience, timing, and reaction speed.
    Up to 4 players battle it out to become the Shogun in an intense one-hit-kill free-for-all.
  </p>),
  (<ul>
    <li><p>Implemented combat mechanics for attacks, blocks, and duels</p></li>
    <li><p>Created special effects for duels to create high-tension moments</p></li>
  </ul>),
  (<p>
    I had more fun making this game than any other I think, largely because getting together to playtest the game was such a blast, even in the early prototype stages.
    This was because we kept the mechanics simple yet impactful.
    Since we playtested so frequently, we iterated on this game a lot, which resulted in us adding several important features such as directional indicators for players and energy recharging on kills.
    This taught me how important it is to find that nugget of fun early on, so the whole team has a more enjoyable time continuing to work on and improve the game.
  </p>)
)

// Negative Space
const negativeItch = "https://bellisimoo.itch.io/negative-space"
const negativeGitHub = "https://github.com/fshh/Negative-Space"
export const NEGATIVE = new GameData(
  ROUTES.NEGATIVE,
  "Negative Space",
  "Oct 2018-Feb 2019",
  "Solo Developer",
  "",
  [
    ["/img/negative/negative1.gif", ""],
    ["/img/negative/negative2.gif", ""],
    ["/img/negative/negative3.gif", ""],
    ["/img/negative/negative4.gif", ""],
    ["/img/negative/negative5.gif", ""]
  ],
  (<p>
    How do you play a platformer when the world fades away with each jump?
  </p>),
  [
    [negativeItch, "Itch.io Page"],
    [negativeGitHub, "GitHub Repo"]
  ],
  (<p>
    Negative Space is a minimalist puzzle-platformer where the world fades away with each jump.
    This game began as a final project for my Rapid Prototyping class, although I continued to work on the game for a month or so after submission.
    Most of the game was developed over the course of three months.
    This project was an entirely solo endeavor; everything but the sounds, fonts, and a shader were made by me.
  </p>),
  (<ul>
    <li><p>Created almost all assets and code independently</p></li>
    <li><p>Managed playtests of several iterative physical and digital prototypes</p></li>
    <li><p>Collected live player feedback notes and digital survey responses</p></li>
  </ul>),
  (<p>
    Negative Space was my 2nd-ever Unity game, and my first time working on a game alone.
    Getting full creative control over a project feels very freeing, but it can be dangerous to not have varied perspectives giving input on a game's direction.
    As a result, I had to learn to base development decisions on playtest results and feedback, in addition just my own personal opinions and ideas.
    I also had to rely on myself to set deadlines and schedule playtests.
    <br /><br />
    On top of all that, this game introduced me to many parts of Unity for the first time.
    2D physics, animations, UI, scenes, and prefabs were all pretty much new to me.
    Learning about all of these systems was challenging but very rewarding, and it gave me all the tools to set up 2D prototypes quickly for future work.
    My favorite part of this game, though, was probably trying a million different tricks to make my programmer art look good.
  </p>)
)

const GAMELIST = [PLUTO, CTRL, NFG, SAMURAI, NEGATIVE]
export default GAMELIST
