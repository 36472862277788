import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

function BottomElement(gameData) {
  if (gameData.trailer) {
    const rows = [];

    rows.push(
      <Row>
        <h2>Screenshots</h2>
      </Row>
    )
    rows.push(
      <Row>
        {gameData.Carousel()}
      </Row>
    )

    return rows;
  } else {
    return null;
  }
}

function GameFull(gameData) {
  return (
    <Container fluid="md">
      <br />
      <Row className="justify-content-center">
        <Col md={8} sm={10} xs={11}>
          <Row className="justify-content-center">
            <h1 className="big-text mt-2">{gameData.title}</h1>
          </Row>
          <Row className="justify-content-center">
            <h5 className="text-muted"><small>{gameData.timeframe}</small></h5>
          </Row>
          <Row className="justify-content-center">
            <h5 className="text-muted">{gameData.role}</h5>
          </Row>
          <Row className="justify-content-center my-2">
            {gameData.PreviewVisual()}
          </Row>
          <Row className="justify-content-center">
            {gameData.LinkButtons()}
          </Row>
        </Col>
      </Row>
      <hr className="my-5" />
      <Row className="justify-content-center">
        <Col md={8} sm={10} xs={11}>
          <Row>
            <h2>About</h2>
          </Row>
          <Row className="blue-link">
            {gameData.about}
          </Row>
          <br />
          <Row>
            <h2>Contributions</h2>
          </Row>
          <Row className="blue-link">
            {gameData.contributions}
          </Row>
          <br />
          <Row>
            <h2>Lessons</h2>
          </Row>
          <Row className="blue-link">
            {gameData.lessons}
          </Row>
          <br />
          {BottomElement(gameData)}
        </Col>
      </Row>
    </Container>
  )
}

export default GameFull