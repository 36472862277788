// React
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// CSS
import './stylesheets/index.css'
import './stylesheets/mystyle.css'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBars, faFileAlt, faArrowRight, faQuestion, faBirthdayCake } from '@fortawesome/free-solid-svg-icons'

// Routes
import * as ROUTES from './constants/routes'

// Pages
import Navigation from './components/Navigation'
import HomePage from './components/HomePage'
import AboutPage from './components/AboutPage'
import Footer from './components/Footer'
import NotFoundPage from './components/NotFoundPage'
import GamesPage from './components/GamesPage'
import PlutoPage from './components/PlutoPage'
import CTRLPage from './components/CTRLPage'
import NFGPage from './components/NFGPage'
import SamuraiPage from './components/SamuraiPage'
import NegativePage from './components/NegativePage'

import ProjectsPage from './components/ProjectsPage'
import EnginePage from './components/EnginePage'
import GraphicsPage from './components/GraphicsPage'
import PlagiarismPage from './components/PlagiarismPage'

library.add(fab, faBars, faFileAlt, faArrowRight, faQuestion, faBirthdayCake)

const App = () => (
	<Router>
		<Navigation />
		
    <main>
      <div className="main-div d-flex">
        <Switch>
          {/* Main Pages */}
          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route exact path={ROUTES.GAMES} component={GamesPage} />
          <Route exact path={ROUTES.PROJECTS} component={ProjectsPage} />
          <Route exact path={ROUTES.ABOUT} component={AboutPage} />

          {/* Game Pages */}
          <Route exact path={ROUTES.PLUTO} component={PlutoPage} />
          <Route exact path={ROUTES.CTRL} component={CTRLPage} />
          <Route exact path={ROUTES.NFG} component={NFGPage} />
          <Route exact path={ROUTES.SAMURAI} component={SamuraiPage} />
          <Route exact path={ROUTES.NEGATIVE} component={NegativePage} />

          {/* Project Pages */}
          <Route exact path={ROUTES.ENGINE} component={EnginePage} />
          <Route exact path={ROUTES.GRAPHICS} component={GraphicsPage} />
          <Route exact path={ROUTES.PLAGIARISM} component={PlagiarismPage} />

          {/* 404 */}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </div>
    </main>
    
    <Footer/>
	</Router>
)

export default App;
