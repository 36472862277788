import React from 'react'
import IconLink from './IconLink'
import * as LINKS from '../constants/links'

function ResumeLink(props) {
  if (props.hideResume) {
    return
  } else {
    return (<IconLink link={LINKS.RESUME} icon='file-alt' tooltip='Resume' placement={props.placement} />)
  }
}

const IconList = (props) => (
  <div className="icon-list">
    {ResumeLink(props)}
    <IconLink link={LINKS.GITHUB} icon={['fab', 'github']} tooltip='Github' placement={props.placement} />
    <IconLink link={LINKS.ITCH} icon={['fab', 'itch-io']} tooltip='itch.io' placement={props.placement} />
    <IconLink link={LINKS.LINKEDIN} icon={['fab', 'linkedin']} tooltip='LinkedIn' placement={props.placement} />
    <IconLink link={LINKS.TWITTER} icon={['fab', 'twitter']} tooltip='Twitter' placement={props.placement} />
  </div>
)

export default IconList