import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ContactInfo from './ContactInfo'
import IconList from './IconList'

const Footer = () => (
  <div className="foot">
    <Container>
      <Row className="justify-content-center">
        <Col md={8} sm={10} xs={11}>
          <Row className="justify-content-center">
            <h6>Belle Knollmeyer</h6>
          </Row>
          <Row className="justify-content-center">
            <p><ContactInfo /></p>
          </Row>
          <Row className="justify-content-center">
            <p><small>Copyright&copy; 2021-2023 Belle Knollmeyer. All rights reserved.</small></p>
          </Row>
          <Row className="justify-content-center">
            <IconList placement="top"/>
          </Row>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Footer