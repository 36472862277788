export const HOME = '/';

export const GAMES = '/games';
export const PLUTO = '/games/plutos-ascent';
export const CTRL = '/games/out-of-ctrl';
export const NFG = '/games/near-field-gladiator';
export const SAMURAI = '/games/samurai-slasher';
export const NEGATIVE = '/games/negative-space';

export const PROJECTS = '/projects';
export const ENGINE = '/projects/engine';
export const GRAPHICS = '/projects/graphics';
export const PLAGIARISM = '/projects/plagiarism-detector';

export const ABOUT = '/about';
export const ERROR = '/404';