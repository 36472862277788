import * as ROUTES from './routes'
import * as LINKS from './links'
import GameData from '../components/GameData'

// Engines Project
const engineSDL = "https://www.libsdl.org/"
const engineGitHub = "https://github.com/fshh/Custom-CPP-Engine"
const engineNuclearThrone = "https://store.steampowered.com/app/242680/Nuclear_Throne/"
const engineTextbook = "https://www.gameenginebook.com/"
export const ENGINE = new GameData(
  ROUTES.ENGINE,
  "Custom C++ Game Engine",
  "Jan-Apr 2020",
  "Programmer, Designer",
  "",
  [
    ["/img/engine/engine1.png", "Nuclear Throne clone"],
    ["/img/engine/engine2.png", "Interactive visual level editor"]
  ],
  (<p>
    A custom-made 2D game engine written in C++ using <a href={engineSDL}>SDL</a>.
  </p>),
  [
    [engineGitHub, "GitHub Repo"]
  ],
  (<p>
    This is a custom 2D game engine written in C++, using <a href={engineSDL}>SDL</a> for graphics, audio, and controls interfacing.
    Myself and 3 other students worked together to create this engine over 3 months for the final project of our Building Game Engines class.
    <br /><br />
    Along with the base engine code, the <a href={engineGitHub}>GitHub repo</a> also contains code for 3 games: a Breakout clone, a 2D platformer, and a small <a href={engineNuclearThrone}>Nuclear Throne</a> clone.
    It also comes with a custom-made interactive visual level editor.
    <br /><br />
    All credit for art assets goes to their original creators.
    <br /><br />
    Features of the engine include:
    <ul>
      <li><p>Resource caching (textures, sounds, fonts, etc.)</p></li>
      <li><p>Entity-component system</p></li>
      <li><p>2D physics & collision detection</p></li>
      <li><p>Custom math library</p></li>
      <li><p>Framerate capping</p></li>
      <li><p>Code profiling</p></li>
      <li><p>State machines</p></li>
      <li><p>Text localization</p></li>
      <li><p>Interactive visual level editor</p></li>
      <li><p>HTML-viewable documentation generation with Doxygen</p></li>
    </ul>
  </p>),
  (<ul>
    <li><p>Developed resource management system (loading/unloading, caching, rendering) with optimization of memory usage in mind</p></li>
    <li><p>Implemented code profiling to search for bottlenecks where performance could be improved</p></li>
    <li><p>Collaborated with teammates on physics, math library, and individual game content</p></li>
    <li><p>Iterated on engine design by developing it in tandem with different games, abstracting out common code</p></li>
  </ul>),
  (<p className="blue-link">
    Using enterprise game engines like Unity is great, but it can be super frustrating when the engine seems to fight against you, even on simple things.
    Working on this project gave me a newfound understanding and appreciation of just how much careful work has to go into producing even the most simple things in a game that we take for granted.
    <br /><br />
    I had a lot of fun learning about the creative optimizations and design ideas that allow engines to be fast and lightweight while still being versatile enough to support many kinds of game designs.
    In particular, I loved reading the textbook that accompanied this project/class, <a href={engineTextbook}>Game Engine Architecture</a>, which was a first for me when it comes to college textbooks.
  </p>)
)

// Graphics Project
const graphicsOpenGL = "https://www.opengl.org/"
const graphicsQt = "https://www.qt.io/"
export const GRAPHICS = new GameData(
  ROUTES.GRAPHICS,
  "OpenGL/Qt Graphics Renderer",
  "Jan-Apr 2020",
  "Programmer",
  "",
  [
    ["/img/graphics/normalmappedmodel.gif", "Models with texture and normal maps. Shown in the gif are several drawing modes: default, wireframe, texture map, normal map, and lighting."],
    ["/img/graphics/scenegraph.gif", "[Please excuse the gif compression here] Solar system model made of textured and lit spheres. Uses a scene graph to model orbiting via parented transforms."],
    ["/img/graphics/cameralights.gif", "Multiple moving colored point lights illuminating a collection of textured quads. The lights move behind and in front of the back wall to create different combinations of light colors."],
    ["/img/graphics/heightfields.png", "Terrain mesh generated by reading from heightmap image."],
    ["/img/graphics/FBOs.png", "Heightfield render with postprocessing effect applied."],
    ["/img/graphics/texturedmodel.gif", "A collection of texture-mapped models. Normal maps were not implemented at this point. Shown in the gif are several drawing modes: default, wireframe, texture map."],
    ["/img/graphics/starfield.gif", "Illusion of travelling through starfield created by applying perspective matrix to moving pixels."],
  ],
  (<p>
    A collection of 3D rendering demos, written in C++ using <a href={graphicsOpenGL}>OpenGL</a> and <a href={graphicsQt}>Qt</a>.
  </p>),
  [],
  (<p>
    This is a collection of 3D graphics demos, written in C++ using <a href={graphicsOpenGL}>OpenGL</a> and <a href={graphicsQt}>Qt</a>.
    These demos were created over the course of a semester for my Computer Graphics class.
    They span from basic image manipulation to multi-object scenes with textured, normal mapped, and lit objects.
    <br /><br />
    <strong>Code available upon request.</strong>
  </p>),
  (<ul>
    <p>
      <li>
        Implemented several core functionalities of 3D hardware rendering, including:
        <ul>
          <li>Drawing triangles using a vertex buffer and an index buffer</li>
          <li>Processing vertex, color, texture, and normal data from .obj files</li>
          <li>Rendering full 3D models with texture and normal maps</li>
          <li>Viewing a scene with a perspective camera</li>
          <li>Dynamically lighting models with multiple point lights</li>
          <li>Applying post-processing effects using a framebuffer</li>
        </ul>
      </li>
      <li>Created a scene graph system for applying transforms to parent and children objects</li>
      <li>Generated a terrain mesh from a heightmap image</li>
      <li>Wrote a 4D vector and matrix math library</li>
    </p>
  </ul>),
  (<p>
    Along with Engines, Graphics was one my favorite classes I've ever taken.
    Working so close to the hardware layer presents a ton of optimization problems that I find super engaging.
    Over the course of my class I created several different graphics demos, ranging from very simple to fairly complex, each building on the last.
    What I learned from that process was that it can be very worth it to resist the temptation to jump in halfway, and instead start off very very simple.
    Gradually building up from there means you better understand the foundations that hold up the more advanced and abstracted layers later on.
  </p>)
)

// Plagiarism Detector
const plagiarismPaper = "https://doi.org/10.1145/872757.872770"
export const PLAGIARISM = new GameData(
  ROUTES.PLAGIARISM,
  "Code Plagiarism Detector",
  "Sep-Dec 2020",
  "Backend Programmer",
  "",
  [
    ["/img/plagiarism/plagiarism1.png", "The application's submissions page"],
    ["/img/plagiarism/plagiarism2.png", "Comparison view of two submissions"],
    ["/img/plagiarism/plagiarism3.png", "UML diagram of backend code"],
    ["/img/plagiarism/plagiarism4.png", "Early sketch of comparison view"],
    ["/img/plagiarism/plagiarism5.png", "Storyboard of submission selection flow"]
  ],
  (<p>
    A web application built for professors to examine student code for plagiarism.
  </p>),
  [
    [LINKS.PLAGIARISM1, "Early Production Plan"],
    [LINKS.PLAGIARISM2, "Post-Production Report"]
  ],
  (<p>
    This application was created in a 4-person group as the final project for our Fundamentals of Software Engineering class.
    It's a web app that allows professors to upload student submissions and compare them to look for evidence of plagiarism.
    <br /><br />
    This application was written in TypeScript, using React for the frontend and Express+Nodemon for the backend.
    Most of the actual programming was done in 1 week, after several weeks spent researching, planning, designing, and prototyping.
    The algorithm used for detecting plagiarism is described in <a href={plagiarismPaper}>this paper</a>.
    <br /><br />
    <strong>Code available upon request.</strong>
  </p>),
  (<ul>
    <li><p>Developed all backend code: fingerprinting algorithm, file uploads, and REST API</p></li>
    <li><p>Prepared for development by designing use cases, UI mockups, and UML diagrams</p></li>
    <li><p>Wrote postmortem report analyzing development methods, challenges, and results</p></li>
    <li><p>Studied research papers to evaluate possible algorithms for plagiarism detection</p></li>
  </ul>),
  (<p>
    This was my first time programming in TypeScript and my first time creating a web application, so I had to learn a lot of new skills over the course of this project.
    Now that it's complete, I have a much better understanding of the motivations behind a client-server relationship, and the role that REST APIs play in connecting the two sides of that relationship.
    Having that understanding before starting development would have helped considerably in informing our design, and would have prevented a lot of refactoring work which was required to connect the backend to the frontend.
  </p>)
)

const PROJECTLIST = [ENGINE, GRAPHICS, PLAGIARISM]
export default PROJECTLIST
