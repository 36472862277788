import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ButtonType(props) {
  if (props.submit) {
    return "submit"
  } else {
    return "button"
  }
}

function Text(props) {
  if (props.arrow) {
    return <p>{props.text} &nbsp;<FontAwesomeIcon icon="arrow-right" /></p>
  } else {
    return <p>{props.text}</p>
  }
}

const CustomButton = (props) => (
  <button className="custom-button" type={ButtonType(props)}>
    {Text(props)}
  </button>
)

export default CustomButton