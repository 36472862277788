import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
 
import * as ROUTES from '../constants/routes';
import { RESUME } from '../constants/links'
import GAMELIST from '../constants/games'
import PROJECTLIST from '../constants/projects'
import IconList from './IconList';
import NavigationDropdown from './NavigationDropdown';

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  gameDropdownItems = (gameList) => {
    var items = []
    gameList.forEach(game => {
      items.push(
        <NavDropdown.Item href={game.route}>{game.title}</NavDropdown.Item>
      )
    });
    return items;
  }

  render() {
    return (
      <Navbar sticky="top" expand="sm">
        <Navbar.Brand>
          <Link to={ROUTES.HOME}>
            <Image src="/img/favicon.png" alt="Website icon of a flower on a lavender background" roundedCircle height={40} width={40} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <FontAwesomeIcon icon="bars" />
        </Navbar.Toggle>
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <Nav.Link href={ROUTES.HOME}>Home</Nav.Link>
            <NavigationDropdown title="Games" href={ROUTES.GAMES} gameList={GAMELIST} />
            <NavigationDropdown title="Other Projects" href={ROUTES.PROJECTS} gameList={PROJECTLIST} />
            <Nav.Link href={RESUME}>Resume</Nav.Link>
            <Nav.Link href={ROUTES.ABOUT}>About/Contact</Nav.Link>
          </Nav>
          <Navbar.Text>
            <IconList placement="bottom"/>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
 
export default Navigation;