import React from 'react'
import { Col, Row } from 'react-bootstrap';
import CustomButton from './CustomButton';

function GamePreview(gameData) {
  return (
    <div className="mt-3 mb-5">
      <a href={gameData.route}>
        <Row>
          <Col lg={8} className="pad0 my-auto">
            <h3>{gameData.title}</h3>
          </Col>
          <Col lg={4} className="pad0 text-right my-auto">
            <h5><i>{gameData.timeframe}</i></h5>
          </Col>
        </Row>
        <Row>
          <p><i>{gameData.role}</i></p>
        </Row>
      </a>
      <Row className="justify-content-center">
        {gameData.PreviewVisual()}
      </Row>
      <Row>
        <p className="mb-0 blue-link">{gameData.shortDescription}</p>
      </Row>
      <Row>
        <a href={gameData.route}>
          <CustomButton text="See More" arrow />
        </a>
      </Row>
    </div>
  )
}

export default GamePreview;